<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import * as moment from 'moment'
DatePicker.methods.displayPopup = function () {
  this.position = {
    left: 0,
    top: "100%",
  };
};
/**
 * Form Validation component
 */
export default {
  page: {
    title: "Form Create Series",
    meta: [{ label: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect, DatePicker },
  data() {
    return {
      title: "Form Create Series",
      items: [
        {
          text: "Home",
          href: "/home",
        },
        {
          text: "Series",
          href: "/series",
        },
        {
          text: "Create Series",
          active: true,
        },
      ],
      form: {
        name: null,
        price: null,
        status: null,
        dateExpire: null,
      },
      typesubmit: false,
      isError: false,
      errorMessage: null,
      options: [
        { name: "Active", value: 1 },
        { name: "Not Active", value: 0 },
      ],
      isLoadingCategory: false,
      isLoadingTag: false,
      isStatusNotSelected: false,
      isDateNotSelected: false,
    };
  },
  validations: {
    form: {
      name: { required },
      price: { required },
      dateExpire: { required },
    },
  },
  methods: {
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    onSubmit(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isError = true;
        this.errorMessage = "please fill the form with valid data";
      } else {
        this.isDateNotSelected = this.form.dateExpire === null ? true : false;
        this.isStatusNotSelected = this.form.status === null ? true : false;

        if (this.isStatusNotSelected || this.isDateNotSelected) {
          return false;
        }

        // do your submit logic here

				let price = this.convertToAngka(this.form.price);
				let date = moment(this.form.dateExpire).format("YYYY-MM-DD"); 
				         
        let param = new FormData();
        param.append("name", this.form.name);
        param.append("price", price);
        param.append("status", this.form.status.value);
        param.append("expire", moment(this.form.dateExpire).format("YYYY-MM-DD"));
				// param.append("expire", date);

        console.log(param);

        this.$axios
          .post("/series", param)
          .then((response) => {
            console.log(response.data);
            Swal.fire("Success", "Series Created Successfully", "success");
						this.$router.push('/series')
          })
          .catch((e) => {
            if (e.response) {
              this.isError = true;
              this.errorMessage = e.response.data.message;
            }
          });
      }
    },
		notBeforeToday(date) {
      return date < new Date(new Date());
    },
    convertToAngka(rupiah) {
      if (rupiah == "") {
        return 0;
      } else {
        return parseInt(rupiah.replace(/,.*|[^0-9]/g, ""), 10);
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              v-model="isError"
              variant="danger"
              class="mt-3"
              dismissible
              >{{ errorMessage }}</b-alert
            >
            <form action="#" @submit.prevent="onSubmit">
              <div class="form-group">
                <label>Name</label>
                <input
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  name="name"
                  :class="{ 'is-invalid': typesubmit && $v.form.name.$error }"
                />
                <div
                  v-if="typesubmit && $v.form.name.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.form.name.required"
                    >This value is required.</span
                  >
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-6">
                  <label>Price</label>
                  <b-input-group prepend="Rp.">
                    <input
                      v-model="form.price"
                      v-mask="'###.###.###.###.###,##'"
                      type="text"
                      class="form-control"
                      name="price"
                      :class="{
                        'is-invalid': typesubmit && $v.form.price.$error,
                      }"
                    />
                    <div
                      v-if="typesubmit && $v.form.price.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.price.required"
                        >This value is required.</span
                      >
                    </div>
                  </b-input-group>
                </div>
                <div class="col-md-6">
                  <label>Date Expire</label>
                  <br />
                  <date-picker
                    v-model="form.dateExpire"
                    format="DD MMMM YYYY"
                    name="date"
                    :first-day-of-week="1"
										:disabled-date="notBeforeToday"
                    lang="en"
                    placeholder="Select One"
                    :class="{ 'is-invalid': isDateNotSelected }"
                  ></date-picker>
                  <div v-if="isDateNotSelected" class="invalid-feedback">
                    <span>This value is required.</span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label>Status</label>
                <multiselect
                  :class="{ 'is-invalid': isStatusNotSelected }"
                  v-model="form.status"
                  deselect-label="Can't remove this value"
                  track-by="name"
                  label="name"
                  placeholder="Select one"
                  :options="options"
                  :searchable="false"
                  :allow-empty="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong>{{ option.name }}</strong></template
                  >
                </multiselect>
                <div v-if="isStatusNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>

              <div class="form-group mb-0">
                <div>
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
  </Layout>
</template>